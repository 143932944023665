.Thumbnails {
  padding-top: 4rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  row-gap: 3rem;
  column-gap: 2rem;
}

.flex-thumbnails {
  width: 47.5%;
  min-width: 28.2rem;
  flex-grow: 1;
}


.thumb-gap {
  gap: 3rem !important;
}