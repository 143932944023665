.lighter-bg {
    background-color: rgba(255, 255, 255, 0.5);
    padding: 2rem;
}

.body-content{
    padding: 2rem 4rem 2rem 4rem;
    width: 78%;
}

.section {
    margin-top: 6rem;
}

.section-body {
    margin-top: 2rem;
}

.project-stats{
    width: 20%;
}

.project-description {
    width: 70%;
}

.smaller-header {
    margin-bottom: 0.2rem;
}

.flex-standard {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1.5rem;
}

.flex-standard-vert {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1.5rem;
}

.flex-section-text {
    width: 55%;
}

.flex-section-image {
    width: 40%;
    min-width: 20rem;
}

.flex-section-third {
    width: 31%;
    min-width: 15rem;
    flex-grow: 1;
}

.flex-section-half {
    width: 47.5%;
    min-width: 20rem;
    flex-grow: 1;
}

/* .flex-section-half-closer {
    width: 48.7%;
} */

.smaller-width {
    width: 70%;
}

.smaller-height {
    width: 40%;
}

.footer {
    padding-bottom: 4rem;
}

.add-row-gap {
    row-gap: 3rem;
}

.wide-column-gap {
    column-gap: 2rem !important;
}

.wider-column-gap {
    column-gap: 5rem !important;
}

.flex-noWrap {
    flex-wrap: nowrap !important;
}
.less-gap {
    margin-bottom: -0.7rem;
}

.flex-reverse-wrap {
    align-items: flex-end !important;
    flex-wrap: wrap-reverse !important;
}