.NBar {
    position: fixed;
    box-shadow: 0 0px 10px 10px rgba(246, 240, 233, 1);
    background-color: rgb(246, 240, 233) !important;
    width: 100%;
}

.nBar-content-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 4.5rem;
    margin: auto;

}
.nBar-text {
    font-size: 2rem;
}

.nBar-links-flex {
    column-gap: 1.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-end;
}