@font-face {
  font-family: Apple Garamond;
  src: url(../public/fonts/apple_garamond/AppleGaramond.ttf);
  font-weight: normal;
}
@font-face {
  font-family: Apple Garamond;
  src: url(../public/fonts/apple_garamond/AppleGaramond-Bold.ttf);
  font-weight: bold;
}
@font-face {
  font-family: Apple Garamond;
  src: url(../public/fonts/apple_garamond/AppleGaramond-Light.ttf);
  font-weight: light;
}
@font-face {
  font-family: Apple Garamond;
  src: url(../public/fonts/apple_garamond/AppleGaramond-Italic.ttf);
  font-style: italic;
}
@font-face {
  font-family: Apple Garamond;
  src: url(../public/fonts/apple_garamond/AppleGaramond-LightItalic.ttf);
  font-weight: light;
  font-style: italic;
}

/* CSS reset */
*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    margin: 0;
}

img,
picture,
video,
canvas,
svg {
    display: block;
    max-width: 100%;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}


body {
  margin: 0;
  font-family: 'Degular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #372A22 !important;
  background-color: #F6F0E9 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: inherit !important;
  text-decoration: inherit !important;
}

.secondaryFont {
  font-family: 'Apple Garamond', serif;
}

.bright-orange {
  color: #FF6B00;
}
.fw-medium {
  font-weight: 500 !important;
}
.display-7 {
  font-size: calc(0.6rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

.no-bottom-margin {
  margin: 0 !important;
}

.added-padding {
  padding: 4rem;
}


.mg-center {
  margin: 0 auto 0 auto;
}

.fix-width {
  width: 69%;
}

.fix-width-wider {
  width: 89.5%;
}

.img-frame-rounded {
  height: auto;
  object-fit: cover;
  box-shadow: 3px 3px 5px 1px rgba(0, 0, 0, .1);
  border-radius: 30px;
}

.img-frame-sharp {
  height: auto;
  object-fit: cover;
  box-shadow: 3px 3px 5px 1px rgba(0, 0, 0, .1);
}

.extra-bottom-padding{
  padding-bottom: 1rem;
}


@media (min-width: 768px) {


}

@media (min-width: 576px) {


}

.caption-text {
  color: #8f6f59;
  font-size: 0.9rem;
}

.padding-under-nbar {
  padding-top: 7rem;
}